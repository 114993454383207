<template>
    <div>
        <v-tabs v-model="tab"
            grow>
            <v-tab>
                <v-icon>mdi-camera</v-icon>
                <span class="ml-3">{{ $t('Active') }} ({{ activeProjectsCount }})</span>
            </v-tab>
            <v-tab>
                <v-icon>mdi-clock</v-icon>
                <span class="ml-3">{{ $t('testing') }} ({{ testingProjectsCount }})</span>
            </v-tab>
            <v-tab>
                <v-icon>mdi-pause-box</v-icon>
                <span class="ml-3">{{ $t('paused') }} ({{ pausedProjectsCount }})</span>
            </v-tab>
            <v-tab>
                <v-icon>mdi-archive</v-icon>
                <span class="ml-3">{{ $t('Archive') }} ({{ archivedProjectsCount }})</span>
            </v-tab>
            <v-tab>
                <v-icon>mdi-folder-open</v-icon>
                <span class="ml-3">{{ $t('All') }} ({{ allProjectsCount }})</span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(projects, idx) in [activeProjects, testingProjects, pausedProjects, inactiveProjects, allProjects]"
                :key="idx"
                eager>
                <DataTable :headers="headers"
                    :data="projects"
                    :selectedItem="selectedItem"
                    :expandable="expandable"
                    :editable="editable"
                    :title="$t('Projects')"
                    :pagination="{sortBy: ['name']}"
                    type="project"
                    v-on:update-items="(arg) => setTabCount(idx, arg)"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            headers: [
                {
                    text: this.$t('id'),
                    align: 'left',
                    sortable: true,
                    value: 'id',
                    type: 'int',
                },
                {
                    text: this.$t('project.name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                    type: 'link',
                    href: item => `/project/${item.id}`,
                    linkIf: item => item.id,
                    linkName: item => `${item.name}`,
                },
                {
                    text: this.$t('Client'),
                    align: 'left',
                    sortable: true,
                    value: 'clientIdent',
                    type: 'link',
                    reset: item => { item.clientIdent = item.client.name },
                    link: item => { this.selectedItem = { $clientId: item.clientId } },
                    linkIf: item => item.clientId,
                    linkName: item => item.clientIdent,
                },
                {
                    text: this.$t('Start Date'),
                    align: 'left',
                    sortable: true,
                    value: 'startDate',
                    type: 'date',
                },
                {
                    text: this.$t('End Date'),
                    align: 'left',
                    sortable: true,
                    value: 'endDate',
                    type: 'date',
                },
                {
                    text: this.$t('Country'),
                    align: 'left',
                    sortable: true,
                    value: 'country',
                    type: 'string',
                },
                {
                    text: this.$t('City'),
                    align: 'left',
                    sortable: true,
                    value: 'city',
                    type: 'string',
                },
                {
                    text: this.$t('cameras'),
                    align: 'left',
                    sortable: true,
                    value: 'cameras',
                    type: 'string',
                    repr: (row, value) => (value ? `${value}/${row.systems.length}` : ''),
                },
                /* {
                    text: this.$t('release'),
                    align: 'left',
                    sortable: true,
                    value: 'releaseStatus',
                    type: 'int',
                },
                {
                    text: this.$t('marketing'),
                    align: 'left',
                    sortable: true,
                    value: 'city',
                    type: 'int',
                }, */
                {
                    text: this.$t('contacts'),
                    align: 'left',
                    sortable: true,
                    value: 'contactNames',
                    type: 'link',
                    href: item => `/contacts/project/${item.id}`,
                    linkIf: item => item.contacts.length > 0,
                    linkIcon: 'mdi-account-circle',
                },
                {
                    text: this.$t('status'),
                    align: 'left',
                    sortable: true,
                    value: 'enabled',
                    type: 'int',
                    selectables: [this.$t('archived'), this.$t('active'), this.$t('testing'), this.$t('paused')],
                    reprType: 'icon',
                    repr: (row, value) => {
                        switch (value) {
                        case 1:
                            return 'mdi-play-circle-outline'
                        case 2:
                            return 'mdi-clock-outline'
                        case 3:
                            return 'mdi-pause-box-outline'
                        default:
                            return 'mdi-close-octagon'
                        }
                    },
                    reprColor: value => {
                        switch (value) {
                        case 1:
                            return 'success'
                        case 2:
                            return 'orange'
                        case 3:
                            return 'browwn'
                        default:
                            return 'red'
                        }
                    },
                },
            ],
            items: [],
            expandable: false,
            editable: true,
            selectedItem: null,
            tab: null,
            activeProjectsCount: 0,
            testingProjectsCount: 0,
            pausedProjectsCount: 0,
            archivedProjectsCount: 0,
            allProjectsCount: 0,
        }
    },

    components: {
        DataTable,
    },

    computed: {
        activeProjects() {
            return this.items.filter(item => item.enabled === 1)
        },

        inactiveProjects() {
            return this.items.filter(item => item.enabled === 0)
        },

        testingProjects() {
            return this.items.filter(item => item.enabled === 2)
        },

        pausedProjects() {
            return this.items.filter(item => item.enabled === 3)
        },

        allProjects() {
            return this.items
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        setTabCount(tabId, count) {
            if (tabId === 0) {
                this.activeProjectsCount = count
            } else if (tabId === 1) {
                this.testingProjectsCount = count
            } else if (tabId === 2) {
                this.pausedProjectsCount = count
            } else if (tabId === 3) {
                this.archivedProjectsCount = count
            } else if (tabId === 4) {
                this.allProjectsCount = count
            }
        },

        getRows() {
            const payload = {
                attributes: {
                    include: [
                        ['client.name', 'clientIdent'],
                    ],
                    exclude: [
                        'updatedAt',
                        'createdAt',
                    ],
                },
                includes: [{
                    type: 'client',
                    attributes: ['id', 'name'],
                },
                {
                    type: 'contact',
                    attributes: ['id', 'name'],
                },
                {
                    type: 'system',
                    attributes: ['id', 'enabled'],
                }],
            }

            rest.getRows('project', payload)
                .then(items => {
                    if (this.$route.params.id) {
                        const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = items[idPos]
                    }

                    items.forEach(item => {
                        if (item.contacts.length > 0) {
                            item.contactNames = item.contacts.filter(contact => contact.name || contact.surname).map(contact => `${contact.name ? contact.name : ''} ${contact.surname ? contact.surname : ''}`).join(' | ')
                        }
                        if (item.systems && item.systems.length > 0) {
                            const cameraCount = item.systems.filter(system => system.enabled).length
                            if (cameraCount > 0) {
                                item.cameras = cameraCount
                            }
                        }
                    })

                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            type: 'project',
            title: 'Projects',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getRows()
    },
}
</script>
